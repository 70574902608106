import { Badge } from '@sparky';
import { styled } from '@sparky/stitches';
import { BadgeProps } from '@sparky/types';

const BadgeWrapper = styled('div', {
  '@md': {
    position: 'absolute',
    top: '$1',
  },
  '@xl': {
    position: 'absolute',
    top: '$3',
    right: '$3',
  },
});

export const TileBarWrapper = styled('div', {
  padding: 0,
  margin: 0,
  li: {
    '@md': {
      '&:hover, &:focus-visible': {
        [`${BadgeWrapper}`]: {
          marginTop: '-$6',
        },
      },
    },
  },
});

export const TopTaskBadge: React.FC<BadgeProps> = props => {
  return (
    <BadgeWrapper>
      <Badge {...props} />
    </BadgeWrapper>
  );
};
